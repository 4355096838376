<template>
  <b-card>
    <div class="flex items-start">
      <b-button
        variant="primary"
        size="sm"
        class="mr-1 rounded-lg p-0"
        @click="$emit('back')"
      >
        <feather-icon
          size="2x"
          icon="ChevronLeftIcon"
        />
      </b-button>
      <div class="text-black mb-2 text-2xl font-semibold">
        Detail Pengajuan Packing
      </div>
    </div>
    <div class="border rounded-lg p-6 space-y-6">
      <b-row
        align-v="center"
        class="text-black"
      >
        <b-col cols="3">
          Status
        </b-col>
        <b-col cols="4">
          <b-badge
            :variant="status === 'Dipacking' ? 'light-info' : 'light-warning'"
          >
            {{ status }}
          </b-badge>
        </b-col>
      </b-row>
      <b-row
        align-v="center"
        class="text-black"
      >
        <b-col cols="3">
          Label Pengiriman
        </b-col>
        <b-col cols="8">
          <b-img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/document-text.svg"
            width="20"
            alt="label"
            class="inline mr-[5px] self-center"
          />
          <b-link
            class="underline !text-primary"
            :href="label_url"
            target="_blank"
          >
            {{ label_name }}
          </b-link>
        </b-col>
      </b-row>
      <b-row
        align-v="center"
        class="text-black"
      >
        <b-col cols="3">
          Jumlah Orderan pada Resi
        </b-col>
        <b-col cols="4">
          {{ grand_total_order }}
        </b-col>
      </b-row>
    </div>
    <div class="mt-6 space-y-6">
      <div class="text-black">
        Rangkuman Order
      </div>
      <BTableSimple bordered>
        <BThead>
          <BTr>
            <BTh class="!capitalize">
              Jenis Order
            </BTh>
            <BTh class="!capitalize">
              Produk
            </BTh>
            <BTh class="!capitalize">
              Variasi
            </BTh>
            <BTh class="!capitalize">
              Jumlah Item
            </BTh>
            <BTh class="!capitalize">
              Jumlah Order
            </BTh>
            <BTh
              v-if="status === 'Dipacking'"
              class="!capitalize"
              style="width: 190px !important;"
            >
              Disetujui Mitra
            </BTh>
          </BTr>
        </BThead>
        <BTbody>
          <BTr
            v-for="(item, index) in items"
            :key="index"
          >
            <!-- Order Number Cell with Rowspan -->
            <BTd
              v-if="item.orderRowspan > 0"
              :rowspan="item.orderRowspan"
              class="align-top"
            >
              {{ item.order_number }}
            </BTd>
            <!-- Product Name -->
            <BTd
              v-if="item.productRowspan > 0"
              :rowspan="item.productRowspan"
              class="align-top"
            >
              {{ item.product_name }}
            </BTd>
            <!-- Product Variant Name -->
            <BTd class="align-top">
              {{ item.product_variant_name }}
            </BTd>
            <!-- Total Item -->
            <BTd
              v-if="item.productRowspan > 0"
              :rowspan="item.productRowspan"
              class="align-top"
            >
              {{ item.total_item }}
            </BTd>
            <!-- Total Order Cell with Rowspan -->
            <BTd
              v-if="item.orderRowspan > 0"
              :rowspan="item.orderRowspan"
              class="align-top"
            >
              {{ item.total_order }}
            </BTd>
            <!-- Total Order Approved Cell with Rowspan -->
            <BTd
              v-if="item.orderRowspan > 0 && status === 'Dipacking'"
              :rowspan="item.orderRowspan"
              style="width: 190px !important;"
              class="align-top"
            >
              <span>{{ item.total_order_approved }}</span>
            </BTd>
          </BTr>
          <BTr v-if="loading">
            <BTd
              colspan="6"
              class="text-center"
            >
              <b-spinner
                class="align-middle mr-6"
                variant="primary"
              />
              <span>Loading...</span>
            </BTd>
          </BTr>
        </BTbody>
      </BTableSimple>
      <div class="text-black space-y-2">
        <b-row align-h="end">
          <b-col cols="2">
            Total Order
          </b-col>
          <b-col cols="0">
            :
          </b-col>
          <b-col cols="2">
            <span>
              {{ grand_total_order }}
            </span>
          </b-col>
        </b-row>
        <b-row align-h="end">
          <b-col cols="2">
            Total Biaya
          </b-col>
          <b-col cols="0">
            :
          </b-col>
          <b-col cols="2">
            <span>
              {{ grand_fulfillment_fee | rupiah }}
            </span>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-card>
</template>

<script>
import { newAxiosIns } from '@/libs/axios'
import filters from '@/libs/filters'

export default {
  filters: { rupiah: filters.rupiah },
  props: {
    detailId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      refId: this.$route.query.ref_id,

      loading: false,
      items: [],
      status: '',
      label_url: '',
      label_name: '',
      grand_total_order: 0,
      grand_fulfillment_fee: 0,
    }
  },
  created() {
    this.fetchDetailPacking()
  },
  methods: {
    async fetchDetailPacking() {
      this.loading = true
      await newAxiosIns
        .get(
          `/kompack/api/v1/outbounds/${this.refId ? this.refId : this.detailId}`,
        )
        .then(res => {
          const { data } = res.data

          this.status = data.status === 'confirmed' ? 'Dipacking' : 'Diajukan'
          this.label_url = data.label_url
          this.label_name = data.label_name
          this.grand_total_order = data.grand_total_order
          this.grand_fulfillment_fee = data.grand_fulfillment_fee

          const result = []
          const orderCount = {}
          const productCount = {}

          data.order_summary.forEach((order, index) => {
            order.products.forEach((product, index2) => {
              if (product.is_bundling && product.product_bundles.length > 0) {
                product.product_bundles.forEach(bundle => {
                  const orderNumber = index + 1
                  const productId = `${index2}_${product.product_id}`

                  if (!orderCount[orderNumber]) {
                    orderCount[orderNumber] = 0
                  }
                  orderCount[orderNumber] += 1

                  if (!productCount[orderNumber]) {
                    productCount[orderNumber] = {}
                  }
                  if (!productCount[orderNumber][productId]) {
                    productCount[orderNumber][productId] = 0
                  }
                  productCount[orderNumber][productId] += 1

                  result.push({
                    order_number: orderNumber,
                    outbound_detail_id: order.outbound_detail_id,
                    total_order: order.total_order,
                    total_order_approved: order.total_order_approved,
                    fulfillment_fee_per_order: order.fulfillment_fee_per_order,
                    product_id: productId,
                    bundle_id: bundle.product_id,
                    product_variant_id: bundle.product_variant_id,
                    product_name: product.product_name,
                    product_variant_name:
                      bundle.product_variant_name || bundle.product_name,
                    total_item: product.total_item,
                    is_bundling: product.is_bundling,
                    product_fulfillment: product.product_fulfillment,
                  })
                })
              } else {
                const orderNumber = index + 1
                const productId = `${index2}_${product.product_id}`

                if (!orderCount[orderNumber]) {
                  orderCount[orderNumber] = 0
                }
                orderCount[orderNumber] += 1

                if (!productCount[orderNumber]) {
                  productCount[orderNumber] = {}
                }
                if (!productCount[orderNumber][productId]) {
                  productCount[orderNumber][productId] = 0
                }
                productCount[orderNumber][productId] += 1

                result.push({
                  order_number: orderNumber,
                  outbound_detail_id: order.outbound_detail_id,
                  total_order: order.total_order,
                  total_order_approved: order.total_order_approved,
                  fulfillment_fee_per_order: order.fulfillment_fee_per_order,
                  product_id: productId,
                  bundle_id: null,
                  product_variant_id: product.product_variant_id,
                  product_name: product.product_name,
                  product_variant_name: product.product_variant_name || '-',
                  total_item: product.total_item,
                  is_bundling: product.is_bundling,
                  product_fulfillment: product.product_fulfillment,
                })
              }
            })
          })

          this.items = result.map((item, index, array) => {
            const orderNumber = item.order_number
            const productId = item.product_id

            let orderRowspan = 0
            let productRowspan = 0

            if (index === 0 || orderNumber !== array[index - 1].order_number) {
              orderRowspan = orderCount[orderNumber]
            }
            if (
              index === 0
              || orderNumber !== array[index - 1].order_number
              || productId !== array[index - 1].product_id
            ) {
              productRowspan = productCount[orderNumber][productId]
            }

            return {
              ...item,
              orderRowspan,
              productRowspan,
            }
          })
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>
